'use client'
import Image from 'next/image'
import SignInForm from '@/components/Auth/SignInForm'
import BackgroundImage from '@public/assets/sign_in_photo.jpg'
import Logo from '@public/assets/logo-blue.svg'
import ChangePasswordForm from '@/components/Auth/ChangePasswordForm'
import { Suspense, useState } from 'react'
import { AuthErrors } from '@/lib/utils'
import LoginConfirmForm from '@/components/Auth/Register/LoginConfirmForm'
import BackButton from '@/components/BackButton'

const SignIn = () => {
  const [isRequiredToChangePassword, setIsRequiredToChangePassword] =
    useState(false)
  const [isRequiredToConfirm, setIsRequiredToConfirm] = useState(false)
  const [tempCredentials, setTempCredentials] = useState<{
    email: string | null
    password: string | null
  }>({
    email: null,
    password: null,
  })
  const loginStateChange = ({
    email,
    password,
    type,
  }: {
    email: string
    password: string
    type: AuthErrors
  }) => {
    type === AuthErrors.changePasswordRequired &&
      setIsRequiredToChangePassword(true)
    type === AuthErrors.userIsNotConfirmed && setIsRequiredToConfirm(true)
    setTempCredentials({ email, password })
  }

  return (
    <div className="grid grid-cols-1 gap-0 lg:grid-cols-[minmax(min-content,680px),_1fr]">
      <div className="flex w-full flex-col items-center px-15 py-10">
        <div className="flex w-full max-w-[460px] flex-col gap-16">
          <div className="flex flex-col items-start gap-10">
            <Image quality={80} src={Logo} alt="" />
            <BackButton />
          </div>
          {!isRequiredToChangePassword && !isRequiredToConfirm && (
            <Suspense>
              <SignInForm action={loginStateChange}></SignInForm>
            </Suspense>
          )}
          {isRequiredToChangePassword && (
            <Suspense>
              <ChangePasswordForm data={tempCredentials}></ChangePasswordForm>
            </Suspense>
          )}
          {isRequiredToConfirm && tempCredentials.email && (
            <Suspense>
              <LoginConfirmForm data={tempCredentials}></LoginConfirmForm>
            </Suspense>
          )}
        </div>
      </div>
      <div className="fixed right-0 top-0 hidden h-dvh w-[calc(100vw_-_680px)] items-center justify-center p-2.5 lg:flex">
        <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-[30px] px-[10%] py-10">
          <Image
            placeholder="blur"
            className="absolute right-0 top-0 h-full w-full object-cover"
            src={BackgroundImage}
            alt=""
          />
        </div>
      </div>
    </div>
  )
}

export default SignIn
