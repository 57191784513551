'use client'
import ChangePassword from '@/components/Auth/ChangePasswordForm/ChangePassword'

const ChangePasswordForm = ({ data }: { data: any }) => {
  return (
    <div className="flex h-full flex-col gap-10">
      <h3 className="text-center text-h3 font-bold md:text-start">
        Change password
      </h3>
      <div className="grid gap-2.5">
        <div className="grid gap-10 rounded-md bg-white">
          <ChangePassword data={data}></ChangePassword>
        </div>
      </div>
    </div>
  )
}

export default ChangePasswordForm
